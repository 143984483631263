<template>
    <div>
        <b-container>
            <section class="interviews">
                <div class="sector pb-0">
                    <h2 class="main-title">Interviews</h2>
                    <p class="main-subtitle">Inspire & be inspired</p>
                    <div v-if="loader" class="profile__content text-center mt-1 mb-3">
                        <b-col cols="12">
                            <Spinner size="medium" line-fg-color="#e91e63" />
                        </b-col>
                    </div>
                    <div id="top" v-else>
                        <paginate name="interviewsList" :list="getInterviews" tag="div" :per="10" class="paginate-list">
                            <b-row>
                                <b-col v-for="interview in paginated('interviewsList')" :key="interview.id" cols="12" lg="6">
                                    <div class="interviews__element">
                                        <div v-if="interview.new" class="custom-ribbon custom-ribbon--right">
                                            <span class="custom-ribbon__text custom-ribbon__text--marina">New</span>
                                        </div>
                                        <div class="interviews__content">
                                            <span class="interviews__date"><span class="agencies__star-tooltip" v-b-tooltip title="Interview date"><simple-line-icons icon="calendar" size="small" color="#504e70" /></span>{{ interview.date }}</span><br class="d-inline d-sm-none">
                                            <span class="interviews__person"><span class="agencies__star-tooltip" v-b-tooltip title="Interviewee"><simple-line-icons icon="user" size="small" color="#504e70" /></span>{{ interview.interviewee }}</span>
                                            <p v-line-clamp:20="1" class="interviews__company">{{ interview.company }}</p>
                                            <h2 v-if="desktopMode" class="interviews__title" v-line-clamp:24="2">{{ interview.title }}</h2>
                                            <h2 v-else class="interviews__title" v-line-clamp:24="0">{{ interview.title }}</h2>
                                        </div>
                                        <div class="events__details">
                                            <router-link class="details-button details-button--full" :to="{ name: 'interview', params: { id: interview.id }}"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Read more</router-link>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </paginate>
                        <paginate-links @change="scrollToTop('top')" v-if="getInterviews.length != 0" for="interviewsList" :limit="3" :show-step-links="true" :classes="{'ul': 'pagination','.next > a': 'next-link','.prev > a': 'prev-link'}"></paginate-links>
                    </div>
                    <div v-if="getInterviews.length == 0 && !loader" class="mb-3">
                        <p class="text-center result-text">No results found</p>
                    </div>
                </div>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import VuePaginate from 'vue-paginate'
import Spinner from 'vue-simple-spinner'
import MoveTo from 'moveto'
export default {
  components: {
    SimpleLineIcons,
    Spinner
  },
  data: function () {
    return {
      loader: true,
      screenWidth: 0,
      desktopMode: false,
      paginate: ['interviewsList']
    }
  },
  created () {
    setTimeout(function () { this.loader = false }.bind(this), 1500)

    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.screenWidth = window.innerWidth
      if (this.screenWidth > 768) {
        this.desktopMode = true
      } else {
        this.desktopMode = false
      }
    },
    scrollToTop (element) {
      const moveTo = new MoveTo({
        tolerance: 75,
        duration: 1000
      })

      const target = document.getElementById(element)

      moveTo.move(target)
    }
  },
  computed: {
    getInterviews () {
      var data = require('../assets/json/interviews.json')
      return Object.values(data).reverse()
    }
  }
}
</script>
