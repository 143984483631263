<template>
    <div>
        <InterviewsList />
    </div>
</template>

<script>
import InterviewsList from '@/components/InterviewsList.vue'
export default {
  components: {
    InterviewsList
  }
}
</script>
